import { graphql } from 'gatsby';
import * as React from 'react';
import MainLayout from '../layouts/MainLayout';
import { PageContext } from '../templates/post';
import * as css from '../layouts/MainLayout.css';

interface NotFoundTemplateProps {
  data: {
    allMarkdownRemark: {
      totalCount: number;
      edges: {
        node: PageContext;
      }[];
    };
  };
}

const NotFoundPage: React.FunctionComponent<NotFoundTemplateProps> = props => {
  return (
    <MainLayout>
      <div className={css.notfound}>PAGE NOT FOUND</div>
      <div className={css.x404}>404</div>
    </MainLayout>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(limit: 3, sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          timeToRead
          frontmatter {
            lang
            title
            date
            tags
            image {
              childImageSharp {
                fluid(maxWidth: 3720) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            author {
              id
              bio
              avatar {
                children {
                  ... on ImageSharp {
                    fixed(quality: 100) {
                      src
                    }
                  }
                }
              }
            }
          }
          excerpt
          fields {
            layout
            slug
          }
        }
      }
    }
  }
`;
